import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { canUseDOM } from 'exenv';

import loadable from '@loadable/component';

const Social = loadable(() => import('../../../components/social/social'));
const MicIcon = loadable(() => import('./MicIcon'));
const MicCarousel = loadable(() => import('./MicCarousel'));
const MicMobileIconCarousel = loadable(() => import('./MicMobileIconCarousel'));
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

/* eslint-disable camelcase, array-callback-return, no-param-reassign, consistent-return, react/no-did-mount-set-state */
const dot_colors = ['#3CFF00', '#00B7FF', '#EF5B24', '#FF0086', '#FFEF00'];
const except_sectors = [
  'North America',
  'Latin America',
  'Europe, Middle East, and Africa',
  'Asia-Pacific'
];

export default class MIC2021 extends Component {
  constructor(props) {
    super(props);

    this.intro = null;
    this.mobileException = null;
    this.mic2021 = React.createRef();
    // Functions
    this.changeTab = this.changeTab.bind(this);
    this.processInitialData = this.processInitialData.bind(this);
    this.addZeroes = this.addZeroes.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.expandDetails = this.expandDetails.bind(this);
    this.showMobileShareButtons = this.showMobileShareButtons.bind(this);
    this.shouldRenderRow = this.shouldRenderRow.bind(this);
    this.determineIfMobile = this.determineIfMobile.bind(this);
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);

    this.scrollToSection = this.scrollToSection.bind(this);

    this.top50 = React.createRef();
    this.sectors = React.createRef();
    this.isDesktop = false;

    this.state = {
      activeTab: 1,
      activeFilter: 'sorter',
      activeFilterDirection: 'asc',
      list_data_1: [],
      list_data_2: [],
      carousel_posts: [],
      currentListSorted: [],
      expandedRow: null,
      showMobileShare: false,
      isMobile: false,
      backToTop: false
    };
  }

  componentDidMount() {
    // this.intro = this.props.posts[0].content;
    this.intro =
      this.props.posts[0].content[0] + this.props.posts[0].content[1];
    this.processInitialData();
    window.addEventListener('scroll', this.handleWindowScroll);

    if (canUseDOM) {
      const mql = window.matchMedia('(max-width: 699px)');
      mql.addListener(this.determineIfMobile);

      this.setState(
        {
          isMobile: window.innerWidth < 700
        },
        () => {
          if (window.location.search.includes('best-teams')) {
            this.changeTab(2);
          }
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  changeTab(tab) {
    if (tab !== this.state.activeTab) {
      this.setState(
        {
          activeTab: tab,
          activeFilterDirection: 'asc'
        },
        () => {
          this.setState({
            expandedRow: null
          });

          if (tab === 1) {
            this.setState({
              currentListSorted: this.state.list_data_1,
              activeFilter: 'sorter'
            });
          } else if (tab === 2) {
            this.setState({
              currentListSorted: sortBy(this.state.list_data_2, [
                o => o.company_name
              ]),
              activeFilter: 'sector_name'
            });
          }
        }
      );
    }
  }

  processInitialData() {
    const list_data_1 = [];
    const list_data_2 = [];

    if (this.props.posts[0].customFields.carousel) {
      this.setState({
        carousel_posts: this.props.posts[0].customFields.carousel.carousel_posts
      });
    }

    this.props.posts[0].customFields.most_innovative_companies_list.list_data_1.map(
      (row, i) => {
        row.sorter = i + 1;
        row.company_name = row.company_name.replace('&amp;', '&');
        list_data_1.push(row);
      }
    );

    this.props.posts[0].customFields.most_innovative_companies_list.list_data_2.map(
      (row, i) => {
        row.rank = i + 1;
        row.company_name = row.sector_name.replace('&amp;', '&');
        row.sector_description = row.sector_description.replace('&amp;', '&');
        row.dot = '3Cff00';
        list_data_2.push(row);
      }
    );

    this.setState({
      activeTab: 1,
      list_data_1,
      list_data_2,
      currentListSorted: list_data_1
    });
  }

  addZeroes(number) {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  }

  handleFilter(sortFilter) {
    let listData = [];

    if (sortFilter !== this.state.activeFilter) {
      this.setState(
        {
          activeFilter: sortFilter,
          activeFilterDirection: 'asc'
        },
        () => {
          switch (this.state.activeTab) {
            case 1:
              listData = sortBy(this.state.list_data_1, [o => o[sortFilter]]);

              this.setState({ currentListSorted: listData });

              break;

            case 2:
              listData = sortBy(this.state.list_data_2, [o => o[sortFilter]]);

              this.setState({ currentListSorted: listData });

              break;

            default:
              return false;
          }
        }
      );
    } else {
      this.setState(
        {
          activeFilterDirection:
            this.state.activeFilterDirection === 'asc' ? 'desc' : 'asc'
        },
        () => {
          switch (this.state.activeTab) {
            case 1:
              listData = sortBy(this.state.list_data_1, [o => o[sortFilter]]);

              if (this.state.activeFilterDirection === 'desc') {
                listData.reverse();
              }

              this.setState({ currentListSorted: listData });

              break;

            case 2:
              listData = sortBy(this.state.list_data_2, [o => o[sortFilter]]);

              this.setState({ currentListSorted: listData });

              break;

            default:
              return false;
          }
        }
      );
    }
  }

  expandDetails(row) {
    if (this.state.activeTab === 1) {
      this.setState({
        expandedRow: row !== this.state.expandedRow ? row : null
      });
    }
    if (
      this.state.activeTab === 2 &&
      this.state.currentListSorted &&
      this.state.currentListSorted[row]
    ) {
      window.open(this.state.currentListSorted[row].article_url);
    }
  }

  showMobileShareButtons() {
    this.setState({
      showMobileShare: this.state.showMobileShare !== true
    });
  }

  shouldRenderRow() {
    return true;
  }

  determineIfMobile(e) {
    this.setState({
      isMobile: e.matches
    });
  }

  handleWindowScroll() {
    const currentScroll = window.pageYOffset;
    const latestScroll = this.lastScroll;

    if (
      currentScroll > latestScroll &&
      latestScroll > 1 &&
      this.state.backToTop
    ) {
      this.setState({ backToTop: false });
    } else if (
      currentScroll < latestScroll &&
      currentScroll < 7000 &&
      !this.state.backToTop
    ) {
      this.setState({ backToTop: true });
    }

    this.lastScroll = currentScroll;
  }

  scrollToSection(element, offset = 100) {
    if (element.current) {
      window.scroll({
        top: element.current.offsetTop - offset,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  handleBackToTop() {
    this.scrollToSection(this.mic2021, 0);
  }

  render() {
    const { list_data_2, isMobile } = this.state;

    const dashboard_icons = list_data_2.filter(
      item => !except_sectors.includes(item.sector_name)
    );

    return (
      <main className="mic-2021" ref={this.mic2021}>
        <header className="header-image">
          <div className="header-container-static">
            {(isMobile && <MicMobileIconCarousel />) || (
              <img
                src="https://images.fastcompany.net/multisite_files/fastcompany/mic2021/header.png"
                alt="Most Innovative Companies 2021"
              />
            )}

            {(isMobile && (
              <span className="header-text">
                The World’s
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  style={{ verticalAlign: 'bottom', marginLeft: 10 }}
                  width={48}
                  height={48}
                  viewBox="0 0 65 65"
                >
                  <circle cx={32.5} cy={32.5} r={32.5} fill="#0f0" />
                  <text
                    transform="rotate(20 -102.423 159.288)"
                    fontSize={15}
                    fontFamily="MonostenStencilLightPro,Monosten Stencil Pro"
                    fontWeight={300}
                    letterSpacing=".02em"
                  >
                    <tspan x={-38.1} y={0}>
                      {'2021'}
                    </tspan>
                  </text>
                </svg>
                <br />
                Most Innovative <br />
                Companies
              </span>
            )) ||
              null}
          </div>
          <div className="header-container">
            <div className="left-float">
              <span className="header-text">
                The World’s
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  style={{ verticalAlign: 'middle', marginLeft: 10 }}
                  width={65}
                  height={65}
                  viewBox="0 0 65 65"
                >
                  <circle cx={32.5} cy={32.5} r={32.5} fill="#0f0" />
                  <text
                    transform="rotate(20 -102.423 159.288)"
                    fontSize={15}
                    fontFamily="MonostenStencilLightPro,Monosten Stencil Pro"
                    fontWeight={300}
                    letterSpacing=".02em"
                  >
                    <tspan x={-38.1} y={0}>
                      {'2021'}
                    </tspan>
                  </text>
                </svg>
                <br />
                Most Innovative <br />
                Companies
              </span>
            </div>
            {dashboard_icons.map(item => (
              <MicIcon item={item} key={item.sector_name} />
            ))}
          </div>
        </header>

        <div className="social-buttons-container">
          <div
            className={`social-buttons ${
              this.state.showMobileShare ? 'active' : 'inactive'
            }`}
          >
            <Social
              enableSticky={false}
              empty={false}
              modifier="post"
              disableStyle={true}
              topValue={this.props.fullPageAdvertorial ? 160 : 111}
              text={encodeURIComponent(get(this.props.posts[0], 'title') || '')}
              uri={
                this.props.posts[0].isNewUrl
                  ? get(this.props.posts[0], 'newUrl', '')
                  : this.props.posts[0].link
              }
              bottomBoundary={`#social--${this.props.posts[0].id}`}
              encapsulateId={`social--${this.props.posts[0].id}`}
              active={true}
              {...this.props}
            />
          </div>
        </div>

        <section className="intro-section">
          <section className="intro">
            <img
              src="https://images.fastcompany.net/assets/mic2021/mic-logo-small-1.svg"
              alt=""
            />
            <span dangerouslySetInnerHTML={{ __html: this.intro }} />
          </section>
          <div className="intro-ad-wrapper">
            <AdContainer type="break " />
          </div>
        </section>

        <section className={`carousel-section${isMobile ? '-mobile' : ''}`}>
          <section className={`carousel-container${isMobile ? '-mobile' : ''}`}>
            <MicCarousel
              posts={this.state.carousel_posts}
              isMobile={this.state.isMobile}
            />
          </section>
        </section>

        <div
          style={{ backgroundColor: 'white', paddingTop: isMobile ? 0 : 10 }}
        >
          <section
            className={`list-container ${
              this.state.activeTab === 1 ? 'first-active' : ''
            }`}
          >
            <nav className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 1 ? 'active' : 'inactive'
                }`}
                onClick={() => this.changeTab(1)}
              >
                <div className="tab-title">
                  {
                    this.props.posts[0].customFields
                      .most_innovative_companies_list.panel_name_1
                  }
                </div>
                <div className="tab-title-mobile">Top 50</div>
              </div>

              <div
                className={`tab ${
                  this.state.activeTab === 2 ? 'active' : 'inactive'
                }`}
                onClick={() => this.changeTab(2)}
              >
                <div className="tab-title">
                  {
                    this.props.posts[0].customFields
                      .most_innovative_companies_list.panel_name_2
                  }
                </div>
                <div className="tab-title-mobile">Sectors</div>
              </div>

              <div
                className="mobile-share"
                onClick={() => this.showMobileShareButtons()}
              >
                <img
                  src="https://images.fastcompany.net/multisite_files/fastcompany/mic2021/mic-share-icon.svg"
                  alt=""
                />
              </div>
            </nav>

            <section className="mobile-list-header">
              <nav className="mobile-filters">
                <span>Sort By</span>
                <select
                  onChange={e => this.handleFilter(e.currentTarget.value)}
                  value={this.state.activeFilter}
                >
                  {this.state.activeTab === 1 && (
                    <option value="rank">Rank</option>
                  )}
                  <option value="company_name">Company Name</option>
                </select>
              </nav>
            </section>

            <div className={`list panel-${this.state.activeTab}`}>
              {this.state.activeTab === 1 && (
                <div className="list-row filters">
                  <div
                    className={`filter rank ${
                      this.state.activeFilter === 'sorter'
                        ? `active ${this.state.activeFilterDirection}`
                        : ''
                    }`}
                    onClick={() => this.handleFilter('sorter')}
                  >
                    Rank <span />
                  </div>
                  <div
                    className={`filter company ${
                      this.state.activeFilter === 'company_name'
                        ? `active ${this.state.activeFilterDirection}`
                        : ''
                    }`}
                    onClick={() => this.handleFilter('company_name')}
                  >
                    Company Name <span />
                  </div>
                </div>
              )}

              {this.state.currentListSorted.map((company, idx) => (
                <React.Fragment>
                  {this.shouldRenderRow(company) && (
                    <div
                      className={`list-row ${
                        this.state.expandedRow === idx ? 'open' : 'closed'
                      } ${
                        company.company_name.toLowerCase() === 'abcellera' &&
                        this.state.activeTab === 2
                          ? 'open-by-default'
                          : ''
                      } ${this.state.activeTab === 2 ? 'link' : ''}`}
                      key={`row-${idx}`}
                      onClick={() => this.expandDetails(idx)}
                    >
                      <div className="list-row-container">
                        {this.state.activeTab === 1 && (
                          <div className="column rank">
                            {this.addZeroes(company.rank)}
                          </div>
                        )}
                        <div
                          className={
                            (this.state.activeTab === 1 && 'column company') ||
                            'column sector'
                          }
                        >
                          {this.state.activeTab === 2 && (
                            <span
                              style={{
                                paddingRight: 10,
                                color: dot_colors[idx % 5]
                              }}
                            >
                              &#11044;
                            </span>
                          )}
                          {company.company_name}{' '}
                          <span
                            className={
                              (this.state.activeTab === 1 &&
                                'company reason') ||
                              'sector sector-reason'
                            }
                          >
                            {this.state.activeTab === 1 && company.reason}
                            {this.state.activeTab === 2 &&
                              company.sector_description}
                          </span>
                        </div>

                        {this.state.activeTab === 1 && (
                          <div className="column icon">
                            <img
                              src="https://images.fastcompany.net/image/upload/v1594782797/fcweb/X_eci9k7.svg"
                              alt=""
                            />
                          </div>
                        )}

                        {this.state.activeTab === 2 && (
                          <div className="column icon">
                            <img
                              src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2217.554%22%20height%3D%2231.544%22%20viewBox%3D%220%200%2017.554%2031.544%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%23fff%3Bopacity%3A0%3B%7D.b%7Bfill%3Anone%3Bstroke%3A%23000%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Crect%20class%3D%22a%22%20width%3D%2216%22%20height%3D%2228%22%2F%3E%3Cline%20class%3D%22b%22%20x1%3D%2222.181%22%20y1%3D%220.212%22%20transform%3D%22translate(1.156%201.179)%20rotate(45)%22%2F%3E%3Cline%20class%3D%22b%22%20x2%3D%220.156%22%20y2%3D%2216.344%22%20transform%3D%22translate(12.954%2019.163)%20rotate(45)%22%2F%3E%3C%2Fsvg%3E"
                              alt=""
                            />
                          </div>
                        )}

                        {this.state.activeTab === 1 && (
                          <div
                            className={`content ${
                              company.image_url ? 'has-image' : 'no-image'
                            }`}
                          >
                            <div className="copy">
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html: company.company_description
                                }}
                              />

                              {company.article_url && (
                                <a
                                  className="readmore-button"
                                  href={company.article_url}
                                  target="_blank"
                                >
                                  Read More
                                </a>
                              )}
                            </div>

                            {company.image_url && (
                              <div className="image">
                                <img src={company.image_url} alt="" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {idx + 1 >= 10 &&
                    (idx + 1) % 10 === 0 &&
                    idx + 1 !== this.state.currentListSorted.length && (
                      <div className="list-row-ad-wrapper">
                        <AdContainer type="break" />
                      </div>
                    )}
                </React.Fragment>
              ))}
              <div
                className={`mic-2021__back-to-top ${
                  this.state.backToTop ? 'mic-2021__back-to-top--active' : ''
                }`}
                onClick={this.handleBackToTop}
              />
            </div>
          </section>
        </div>
      </main>
    );
  }
}

MIC2021.propTypes = {
  posts: PropTypes.shape([]).isRequired,
  fullPageAdvertorial: PropTypes.bool.isRequired
};
